<template>
  <div class="white--text text-center background">
    <p class="text-center mt-12 pt-4 fn16">
      {{ quizData.title }}
    </p>
    <v-divider dark class="mb-8"></v-divider>
    <!-- <p class="fn20">คำถามข้อ 2 / {{ questions.length }}</p> -->
    <v-row
      v-for="(body, index) in quizData.question_list"
      :key="index"
      justify="center"
    >
      <div
        class="mb-4 pa-4 mx-4"
        style="text-align: -webkit-center"
        v-if="page == index"
      >
        <v-row align="center" justify="center">
          <v-col cols="12" class="mt-4">
            <span sclass="fn20"
              >คำถามข้อ {{ page + 1 }} / {{ quizData.question_list.length }}
            </span></v-col
          >
          <v-col cols="12" class="mb-6">
            <span class="fn32">{{ body.title }} </span>
          </v-col>
          <v-col cols="12" v-for="(answer, index) in body.option" :key="index">
            <v-btn
              v-if="!clicked"
              @click="handleResponse(answer, body.point)"
              :data-id="answer.id"
              block
              large
              rounded
              class="fn16"
              ><span style="font-weight: 900; color: #001b6a">{{
                answer.choice
              }}</span></v-btn
            >
            <v-btn
              v-if="clicked"
              :data-id="answer.id"
              :class="{
                'green lighten-2': answer.isAnswer,
                'red lighten-2':
                  answer.id == selectedAnswer.id && !answer.isAnswer,
              }"
              block
              large
              rounded
              class="fn16"
              ><span style="font-weight: 900; color: #001b6a">{{
                answer.choice
              }}</span></v-btn
            >
          </v-col>
          <!-- <li
            v-for="answer in question.answers"
            :key="answer"
            class="ma-4 fn20"
            style="list-style-type: none; font-weight: 600"
          >
            <div
              class="mb-8 py-2 px-2 rounded-pill white--text"
              :class="{
                'green lighten-2': answer.correct,
                'red lighten-2':
                  answer.id == selectedAnswer.id && !answer.correct,
              }"
            >
              {{ answer.answer }}
            </div>
          </li> -->
          <!-- v-if="page < quizData.question_list.length" -->
          <v-col cols="12" class="mt-6">
            <v-btn
              v-if="page !== quizData.question_list.length - 1"
              block
              text
              @click="pluspage(index)"
              dark
              ><b>ถัดไป</b>
              <!-- {{ page }} {{ quizData.question_list.length }} -->
            </v-btn>
            <v-btn
              v-if="page == quizData.question_list.length - 1"
              block
              text
              @click="submit()"
              dark
              ><b>จบเกม</b>
              <!-- {{ page }} -->
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-dialog v-model="dialogResult" max-width="90%">
        <v-card>
          <v-container>
            <v-row
              align="center"
              justify="center"
              style="text-align: -webkit-center"
              class="my-8"
            >
              <v-col cols="12" class="text-ceter">
                <span class="fn24"> Congratulations 🎉🎉 </span>
              </v-col>
              <v-col cols="12">
                <span class="fn32" style="font-weight: 800"
                  >Your Score {{ score }}/{{ point }}</span
                >
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  large
                  outlined
                  x-large
                  class="font-weight-bold rounded-lg text-capitalize btncolor"
                  @click="(dialogResult = false), goToHome()"
                >
                  Back to Homepage
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- คำถาม -->
    <!-- <div v-if="state == 'question'" class="mb-4">
      <div class="mb-8 white--text" style="font-weight: 700; font-size: 32px">
        {{ question.question }}
      </div>
      <ul>
        <li
          v-for="answer in question.answers"
          :key="answer"
          class="ma-4 fn20"
          style="list-style-type: none; font-weight: 600"
        >
          <v-btn
            class="mb-8 rounded-pill"
            style="
              background: linear-gradient(
                268.1deg,
                #d5e4ff 7.54%,
                #c1d1ff 100.72%
              );
            "
            block
            large
            @click="handleResponse(answer)"
            :data-id="answer.id"
          >
            {{ answer.answer }}
          </v-btn>
        </li>
        <li
          v-for="answer in question.answers"
          :key="answer"
          class="ma-4 fn20"
          style="list-style-type: none; font-weight: 600"
        >
          <div
            class="mb-8 py-2 px-2 rounded-pill white--text"
            :class="{
              'green lighten-2': answer.correct,
              'red lighten-2':
                answer.id == selectedAnswer.id && !answer.correct,
            }"
          >
            {{ answer.answer }}
          </div>
        </li>
        <li style="list-style-type: none">
          <v-btn text color="white">ข้าม <a-icon type="right" /></v-btn>
        </li>
      </ul>
    </div> -->
    <!-- คำตอบ -->
    <!-- <div v-if="state == 'answer'" class="mb-4">
      <div class="mb-8 white--text" style="font-weight: 700; font-size: 32px">
        {{ question.question }}
      </div>
      <ul>
        <li
          v-for="answer in question.answers"
          :key="answer"
          class="ma-4 fn20"
          style="list-style-type: none; font-weight: 600"
        >
          <div
            class="mb-8 py-2 px-2 rounded-pill white--text"
            :class="{
              'green lighten-2': answer.correct,
              'red lighten-2':
                answer.id == selectedAnswer.id && !answer.correct,
            }"
          >
            {{ answer.answer }}
          </div>
        </li>
      </ul>
      <div class="text-center">
        <button @click="handleNext">ไปข้อถัดไป <a-icon type="right" /></button>
      </div>
    </div> -->
    <!-- <div v-if="state == 'results'">
      <div class="mb-5 white--text" style="font-weight: 700; font-size: 32px">
        คะแนนรวมทั้งหมด
      </div>
      <h3 class="my-5 py-3 white--text">
        {{ score }} / {{ questions.length }}
      </h3>
      <div class="text-center">
        <button v-on:click="handleRestart">เริ่มใหม่</button>
      </div>
    </div> -->
    <!-- </v-parallax> -->
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { Encode, Decode } from "@/services";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      dialogResult: false,
      style: "preclick",
      image: require("@/assets/BG_Quiz.png"),
      imageCorrect: require("@/assets/BG_correct.png"),
      state: "question",
      currentQuestion: 0,
      selectedAnswer: 0,
      score: 0,

      // questions: [
      //   {
      //     question: `FAS ย่อมาจากอะไร`,
      //     no: 1,
      //     answers: [
      //       {
      //         answer: "Feline Atopic Syndrome",
      //         correct: false,
      //       },
      //       {
      //         answer: "Feline Anti-allegic Syndrome",
      //         correct: true,
      //       },
      //       {
      //         answer: "Feline Animal Syndrome",
      //         correct: false,
      //       },
      //     ],
      //   },
      //   {
      //     question: `allergen avoidance คืออะไร`,
      //     no: 2,
      //     answers: [
      //       {
      //         answer: "การหลีกเลี่ยงสารก่อภูมิแพ้",
      //         correct: true,
      //       },
      //       {
      //         answer: "อาการที่แมวที่เป็นภูมิแพ้ควรหลีกเลี่ยง",
      //         correct: false,
      //       },
      //       {
      //         answer: "การเลี่ยงสัตว์ที่เป็นภูมิแพ้",
      //         correct: false,
      //       },
      //     ],
      //   },
      // ],

      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      items: [],
      search: "",
      count: 0,
      quizData: [],
      stepper: 1,
      clicked: false,
      page: 0,
      point: 0,
    };
  },
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
    scorePercentage() {
      return (this.score * 100) / this.questions.length;
    },
  },
  async created() {
    await this.getOneQuiz();
    await this.getProfile();
    // await this.getUser();
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      console.log("lineIdlineIdlineIdlineId", this.lineId);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
    },
    goToHome() {
      this.$router.push("/");
    },
    async submit() {
      // this.point += point;
      console.log("answer", this.score);
      console.log("point", this.point);
      const id = this.$route.query.id;
      const data = {
        quizId: id,
        userId: this.userData.id,
        point: this.point,
        total: this.score,
        history: [
          // {
          //   title: "history1",
          //   title2: "history2",
          // },
          // {
          //   title: "history1",
          // },
        ],
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/quizAnswers`,
        // `http://localhost:8081/quizAnswers`,
        data
      );
      console.log("response", response.data.data);
      this.dialogResult = true;
      this.getPoint(id);
    },
    async getPoint(id) {
      const data = {
        addPoint: this.score,
        type: "QUIZ",
        quizId: id,
        // knowledgeCategory3Id: 1,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/${this.userData.id}`,
        // `http://localhost:8081/users/${this.userData.id}`,
        data
      );
      console.log("response", response.data);
      // message: "User was updated successfully"
      // response_status: "SUCCESS"
      if (response.data.response_status == "SUCCESS") {
        this.dialogSuccess = true;
      }
    },
    pluspage(index) {
      if (this.clicked) {
        this.page = index + 1;
        this.clicked = !this.clicked;
      }
    },
    async getOneQuiz() {
      const id = this.$route.query.id;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/quizs/${id}`
        // `http://localhost:8081/quizs/${id}`
      );
      // const response = await this.axios.get(`${process.env.VUE_APP_API}/quizs/${id}`);
      console.log("response", response.data.data);
      this.quizData = response.data.data;
      // this.slides.push(response.data.data);
    },
    handleResponse(answer, point) {
      // console.log("point", point);
      // console.log("score", this.score);
      // ข้อนี้ มีคะแนนเท่าไหร่ 10

      // this.point = point
      this.point += point;
      this.selectedAnswer = answer;
      this.clicked = true;
      if (answer.isAnswer) {
        console.log(answer.isAnswer, "selected.isAnswer");
        this.$swal.fire({
          icon: "success",
          backdrop: `rgba(82,196,26,0.4) url('https://c.tenor.com/pz0zpQXlK5sAAAAi/sparkle-confetti.gif') left top
    no-repeat`,
          text: "correct !",
          showConfirmButton: false,
          timer: 2000,
        });
        this.score += point;
      } else {
        this.$swal.fire({
          icon: "error",
          text: "Incorrect !",
          backdrop: `rgba(245,34,45,0.4)`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      // this.state = "answer";
    },
    handleNext(event) {
      this.currentQuestion++;
      if (this.currentQuestion >= this.questions.length) {
        this.state = "results";
      } else {
        this.state = "question";
      }
    },
    // randomizeOrder() {
    //   this.questions.map((question) => {
    //     question.answers.sort((a, b) => Math.random() - 0.5);
    //     question.answers.map((answer, index) => (answer.id = index));
    //   });
    //   this.questions.sort((a, b) => Math.random() - 0.5);
    // },
    handleRestart(event) {
      this.currentQuestion = 0;
      this.score = 0;
      this.state = "question";
    },
  },
};
</script>

<style scoped>
body {
  height: 100%;
  /* margin: 0; */
}
.preclick {
  background: linear-gradient(268.1deg, #d5e4ff 7.54%, #c1d1ff 100.72%);
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;

  background: #3e5087;
}
.btncolor {
  color: white;
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
</style>
